import { render, staticRenderFns } from "./OrganismCartSpacesBuilding.vue?vue&type=template&id=11530b12&"
import script from "./OrganismCartSpacesBuilding.vue?vue&type=script&lang=js&"
export * from "./OrganismCartSpacesBuilding.vue?vue&type=script&lang=js&"
import style0 from "./OrganismCartSpacesBuilding.vue?vue&type=style&index=0&id=11530b12&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismCartSpacesBuilding.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fproject%2Fbuilding%2FOrganismCartSpacesBuilding.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculeSpaceDetailsTotalResultCart: require('/app/components/molecules/project/building/MoleculeSpaceDetailsTotalResultCart.vue').default,AtomsCommonAtomSimpleButton: require('/app/components/atoms/common/AtomSimpleButton.vue').default})
