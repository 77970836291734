var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"organism-space"},[_c('div',{staticClass:"general-elements-style"},[(!_vm.isMobileDevice)?_c('MoleculesProjectMoleculeZoomSlider'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"buttons-right active"},[_c('div',{staticClass:"buttons-3D"},[_c('div',{ref:"button2d",staticClass:"button-tooltip"},[_c('span',{class:{
              'btn-tooltip': true,
              'change-view': true,
              text2D: true,
              iconTooltip: true
            },on:{"click":_vm.changeViewMode2D}},[_vm._v("\n            2D\n          ")]),_vm._v(" "),_c('p',{staticClass:"hidden-mobile-elements"},[_vm._v("Select 2D View Mode")])]),_vm._v(" "),_c('div',{ref:"button3d",staticClass:"button-tooltip"},[_c('span',{class:{
              'btn-tooltip': true,
              'change-view': true,
              text3D: true,
              iconTooltip: true,
              active: true
            },on:{"click":_vm.changeViewMode3D}},[_vm._v("3D")]),_vm._v(" "),_c('p',{staticClass:"hidden-mobile-elements"},[_vm._v("Select 3D View Mode")])]),_vm._v(" "),_c('div',{ref:"buttonVt",staticClass:"button-tooltip"},[_c('span',{class:{
              'btn-tooltip': true,
              'change-view': true,
              walkThrough: true,
              iconTooltip: true
            },on:{"click":_vm.changeViewModeFirstPerson}},[_c('AtomIcon',{attrs:{"icon":"street-view","size":20}})],1),_vm._v(" "),_c('p',{staticClass:"hidden-mobile-elements"},[_vm._v("Enter Virtual Tour")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }