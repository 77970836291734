import { render, staticRenderFns } from "./OrganismPinInfoBox.vue?vue&type=template&id=b2aec8ba&"
import script from "./OrganismPinInfoBox.vue?vue&type=script&lang=js&"
export * from "./OrganismPinInfoBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculePinInfoContent: require('/app/components/molecules/project/building/MoleculePinInfoContent.vue').default})
